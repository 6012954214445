import { useCallback, useContext } from 'preact/compat';
import { TranslationsInfoContext } from '../global-contexts';
const placeholderPattern = /{([^}]+)}/g;
export default function useTranslations() {
    const { translations, update } = useContext(TranslationsInfoContext);
    const translate = useCallback((translationKey, placeholders)=>{
        const translation = translations[translationKey];
        if (!translation) {
            return translationKey;
        }
        if (!placeholders) {
            return translation;
        }
        return translation.replace(placeholderPattern, (_, placeholderName)=>{
            const placeholderValue = placeholders[placeholderName];
            return placeholderValue || placeholderValue === 0 ? String(placeholderValue) : '';
        });
    }, [
        translations
    ]);
    return {
        translations,
        update,
        translate
    };
}
