const darkThemeColors = {
    '--white-color': '#FFFFFF',
    '--black-color': '#0F0F0F',
    '--grey500-color': '#808080',
    '--grey400-color': '#A8A8A8',
    '--positive500-color': '#008C99',
    '--positive200-color': '#CCF0EA',
    '--negative500-color': '#FF526F',
    '--negative200-color': '#FCE3E8',
    '--supporting500-color': '#794CFF',
    '--supporting200-color': '#EAE3FC',
    '--warning500-color': '#FFBA42',
    '--warning200-color': '#FDF1C3',
    '--default-fill-color': '#0F0F0F',
    '--default-text-color': '#FFFFFF',
    '--strong-fill-color': '#696969',
    '--medium-fill-color': '#333333',
    '--mild-fill-color': '#1F1F1F',
    '--mild-text-color': '#A8A8A8',
    '--negative-text-color': '#FF526F',
    '--negative-fill-color': '#FF526F',
    '--positive-text-color': '#14B8B8',
    '--positive-fill-color': '#008C99',
    '--default-border-color': '#545454',
    '--mild-border-color': '#333333',
    '--default-button-fill-color': '#333333',
    '--default-button-text-color': '#FFFFFF',
    '--primary-button-fill-color': '#FFFFFF',
    '--primary-button-text-color': '#0F0F0F',
    '--chart-positive-fill-color': '#002F33',
    '--chart-negative-fill-color': '#552029'
};
const lightThemeColors = {
    '--white-color': '#FFFFFF',
    '--black-color': '#0F0F0F',
    '--grey500-color': '#808080',
    '--grey400-color': '#A8A8A8',
    '--positive500-color': '#008C99',
    '--positive200-color': '#CCF0EA',
    '--negative500-color': '#FF526F',
    '--negative200-color': '#FCE3E8',
    '--supporting500-color': '#794CFF',
    '--supporting200-color': '#EAE3FC',
    '--warning500-color': '#FFBA42',
    '--warning200-color': '#FDF1C3',
    '--default-fill-color': '#FFFFFF',
    '--default-text-color': '#0F0F0F',
    '--strong-fill-color': '#A8A8A8',
    '--medium-fill-color': '#EBEBEB',
    '--mild-fill-color': '#F7F7F7',
    '--mild-text-color': '#696969',
    '--negative-text-color': '#C03048',
    '--negative-fill-color': '#FF526F',
    '--positive-text-color': '#00707A',
    '--positive-fill-color': '#008C99',
    '--default-border-color': '#D9D9D9',
    '--mild-border-color': '#EBEBEB',
    '--default-button-fill-color': '#EBEBEB',
    '--default-button-text-color': '#0F0F0F',
    '--primary-button-fill-color': '#0F0F0F',
    '--primary-button-text-color': '#FFFFFF',
    '--chart-positive-fill-color': '#E7F8F4',
    '--chart-negative-fill-color': '#FDF1F3'
};
/**
 * @description Should export the same values as in CSS file.
 *  We have to use HEX notation here as most of the JS libraries (charts, Cordova plugins) accept only this format.
 * @see {@link ../../../styles/variables.module.css}
 * @param {Theme} theme
 * @returns {Colors}
 */ export default function getColors(theme) {
    return theme === 'dark' ? darkThemeColors : lightThemeColors;
}
