import useAsync from '@finst/core/src/scripts/hooks/use-async';
import useStateFromProp from '@finst/core/src/scripts/hooks/use-state-from-prop';
import { useCallback } from 'preact/compat';
import getNews from '../../../services/news/get-news';
const defaultValue = {
    items: [],
    totalCount: 0
};
export default function useNewsLoader(props) {
    const { language, assetSymbols, pageSize = 10, firstPageSize = pageSize } = props;
    const [pageNumber, setPageNumber] = useStateFromProp(language, ()=>1);
    const { isLoading, value = defaultValue } = useAsync(async ()=>getNews({
            pageNumber: 1,
            // news API supports 50 items max
            pageSize: 50,
            language,
            assetSymbols,
            requireTotal: true
        }), [
        language,
        String(assetSymbols)
    ]);
    const loadMore = useCallback(()=>setPageNumber((pageNumber)=>pageNumber + 1), [
        setPageNumber
    ]);
    const { items, totalCount = items.length } = value;
    const news = items.slice(0, firstPageSize + pageSize * (pageNumber - 1));
    return {
        isLoading,
        news,
        hasMore: totalCount > news.length,
        loadMore
    };
}
