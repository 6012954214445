import { useCallback } from 'preact/compat';
import useTranslations from './use-translations';
export default function useBiometricCodeErrorTranslator() {
    const { translate } = useTranslations();
    return useCallback((error)=>{
        return error.code === 'KeyPermanentlyInvalidatedException' ? translate('identity.login.errors.biometricCode.permanentlyInvalidated') : translate('identity.login.errors.biometricCode.failed');
    }, [
        translate
    ]);
}
